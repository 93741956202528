import {useNavigate} from "react-router-dom";
import {convertEnToPe} from "../utiles/Ops";
import Helmet from "react-helmet";

export default function Page401() {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col justify-between items-center mt-[30%]">
            <Helmet>
                <title>صفحه 401</title>
            </Helmet>
            <h1 className="text-9xl">{convertEnToPe(401)}</h1>
            <span>شما دسترسی به این صفحه را ندارید</span>
            <button
                className="px-4 py-3 rounded bg-blue-900 mt-14"
                onClick={() => navigate("/login")}
            >
                رفتن به صفحه لاگین
            </button>
        </div>
    );
}
