export const IconUserBold = ({ color }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="6" r="4" fill="${color || "ccc"}"/>
<ellipse cx="12" cy="17" rx="7" ry="4" fill="${color || "ccc"}"/>
</svg>
`,
    }}
  />
);
export const IconUser = ({ className }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg class="${className}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C9.37665 1.25 7.25 3.37665 7.25 6C7.25 8.62335 9.37665 10.75 12 10.75C14.6234 10.75 16.75 8.62335 16.75 6C16.75 3.37665 14.6234 1.25 12 1.25ZM8.75 6C8.75 4.20507 10.2051 2.75 12 2.75C13.7949 2.75 15.25 4.20507 15.25 6C15.25 7.79493 13.7949 9.25 12 9.25C10.2051 9.25 8.75 7.79493 8.75 6Z" fill="#ccc"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 12.25C9.96067 12.25 8.07752 12.7208 6.67815 13.5204C5.3 14.3079 4.25 15.5101 4.25 17C4.25 18.4899 5.3 19.6921 6.67815 20.4796C8.07752 21.2792 9.96067 21.75 12 21.75C14.0393 21.75 15.9225 21.2792 17.3219 20.4796C18.7 19.6921 19.75 18.4899 19.75 17C19.75 15.5101 18.7 14.3079 17.3219 13.5204C15.9225 12.7208 14.0393 12.25 12 12.25ZM5.75 17C5.75 16.2807 6.26701 15.483 7.42236 14.8228C8.55649 14.1747 10.1733 13.75 12 13.75C13.8267 13.75 15.4435 14.1747 16.5776 14.8228C17.733 15.483 18.25 16.2807 18.25 17C18.25 17.7193 17.733 18.517 16.5776 19.1772C15.4435 19.8253 13.8267 20.25 12 20.25C10.1733 20.25 8.55649 19.8253 7.42236 19.1772C6.26701 18.517 5.75 17.7193 5.75 17Z" fill="#ccc"/>
</svg>
`,
    }}
  />
);
export const IconUserPlus = ({ className }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25013 6C5.25013 3.37665 7.37678 1.25 10.0001 1.25C12.6235 1.25 14.7501 3.37665 14.7501 6C14.7501 8.62335 12.6235 10.75 10.0001 10.75C7.37678 10.75 5.25013 8.62335 5.25013 6ZM10.0001 2.75C8.20521 2.75 6.75013 4.20507 6.75013 6C6.75013 7.79493 8.20521 9.25 10.0001 9.25C11.7951 9.25 13.2501 7.79493 13.2501 6C13.2501 4.20507 11.7951 2.75 10.0001 2.75Z" fill="#ccc"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.97558 13.6643C5.55506 12.7759 7.68658 12.25 10.0001 12.25C12.3137 12.25 14.4452 12.7759 16.0247 13.6643C17.5807 14.5396 18.7501 15.8661 18.7501 17.5L18.7502 17.602C18.7513 18.7638 18.7527 20.222 17.4737 21.2635C16.8443 21.7761 15.9637 22.1406 14.7739 22.3815C13.5809 22.6229 12.0259 22.75 10.0001 22.75C7.97436 22.75 6.4194 22.6229 5.22634 22.3815C4.03661 22.1406 3.15602 21.7761 2.52655 21.2635C1.24752 20.222 1.24894 18.7638 1.25007 17.602L1.25013 17.5C1.25013 15.8661 2.41962 14.5396 3.97558 13.6643ZM4.71098 14.9717C3.37151 15.7251 2.75013 16.6487 2.75013 17.5C2.75013 18.8078 2.79045 19.544 3.47372 20.1004C3.84425 20.4022 4.46366 20.6967 5.52393 20.9113C6.58087 21.1252 8.02591 21.25 10.0001 21.25C11.9744 21.25 13.4194 21.1252 14.4763 20.9113C15.5366 20.6967 16.156 20.4022 16.5265 20.1004C17.2098 19.544 17.2501 18.8078 17.2501 17.5C17.2501 16.6487 16.6288 15.7251 15.2893 14.9717C13.9733 14.2315 12.1049 13.75 10.0001 13.75C7.89541 13.75 6.02693 14.2315 4.71098 14.9717Z" fill="#ccc"/>
<path d="M19.7501 8C19.7501 7.58579 19.4143 7.25 19.0001 7.25C18.5859 7.25 18.2501 7.58579 18.2501 8V9.25002H17.0001C16.5859 9.25002 16.2501 9.5858 16.2501 10C16.2501 10.4142 16.5859 10.75 17.0001 10.75H18.2501V12C18.2501 12.4142 18.5859 12.75 19.0001 12.75C19.4143 12.75 19.7501 12.4142 19.7501 12V10.75H21.0001C21.4143 10.75 21.7501 10.4142 21.7501 10C21.7501 9.5858 21.4143 9.25002 21.0001 9.25002H19.7501V8Z" fill="#ccc"/>
</svg>
`,
    }}
  />
);

export const IconUserPlusBold = ({ className }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="6" r="4" fill="#ccc"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.0001 16.3358C19.4144 16.3358 19.7501 16.6716 19.7501 17.0858V17.75H20.4144C20.8286 17.75 21.1644 18.0858 21.1644 18.5C21.1644 18.9142 20.8286 19.25 20.4144 19.25H19.7501V19.9142C19.7501 20.3284 19.4144 20.6642 19.0001 20.6642C18.5859 20.6642 18.2501 20.3284 18.2501 19.9142L18.2501 19.25H17.5859C17.1717 19.25 16.8359 18.9142 16.8359 18.5C16.8359 18.0858 17.1717 17.75 17.5859 17.75H18.2501L18.2501 17.0858C18.2501 16.6716 18.5859 16.3358 19.0001 16.3358Z" fill="#ccc"/>
<path d="M16.1421 20.2259C14.9822 20.7131 13.55 21.001 12 21.001C8.13401 21.001 5 19.2101 5 17.001C5 14.7918 8.13401 13.001 12 13.001C14.6101 13.001 16.8866 13.8173 18.0902 15.0275C17.5167 15.2814 17.0692 15.7679 16.8673 16.3674C15.9771 16.6672 15.3359 17.5088 15.3359 18.5002C15.3359 19.1932 15.6493 19.8131 16.1421 20.2259Z" fill="#ccc"/>
</svg>
`,
    }}
  />
);
export const IconUserSocial = ({ color }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="6" r="4" stroke="${color || "#ccc"}" stroke-width="1.5"/>
<path d="M18 9C19.6569 9 21 7.88071 21 6.5C21 5.11929 19.6569 4 18 4" stroke="${color || "#ccc"}" stroke-width="1.5" stroke-linecap="round"/>
<path d="M6 9C4.34315 9 3 7.88071 3 6.5C3 5.11929 4.34315 4 6 4" stroke="${color || "#ccc"}" stroke-width="1.5" stroke-linecap="round"/>
<ellipse cx="12" cy="17" rx="6" ry="4" stroke="${color || "#ccc"}" stroke-width="1.5"/>
<path d="M20 19C21.7542 18.6153 23 17.6411 23 16.5C23 15.3589 21.7542 14.3847 20 14" stroke="${color || "#ccc"}" stroke-width="1.5" stroke-linecap="round"/>
<path d="M4 19C2.24575 18.6153 1 17.6411 1 16.5C1 15.3589 2.24575 14.3847 4 14" stroke="${color || "#ccc"}" stroke-width="1.5" stroke-linecap="round"/>
</svg>
`,
    }}
  />
);
export const IconEdit = ({ color }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5189 16.5013C16.6939 16.3648 16.8526 16.2061 17.1701 15.8886L21.1275 11.9312C21.2231 11.8356 21.1793 11.6708 21.0515 11.6264C20.5844 11.4644 19.9767 11.1601 19.4083 10.5917C18.8399 10.0233 18.5356 9.41561 18.3736 8.94849C18.3292 8.82066 18.1644 8.77687 18.0688 8.87254L14.1114 12.8299C13.7939 13.1474 13.6352 13.3061 13.4987 13.4811C13.3377 13.6876 13.1996 13.9109 13.087 14.1473C12.9915 14.3476 12.9205 14.5606 12.7786 14.9865L12.5951 15.5368L12.3034 16.4118L12.0299 17.2323C11.9601 17.4419 12.0146 17.6729 12.1708 17.8292C12.3271 17.9854 12.5581 18.0399 12.7677 17.9701L13.5882 17.6966L14.4632 17.4049L15.0135 17.2214L15.0136 17.2214C15.4394 17.0795 15.6524 17.0085 15.8527 16.913C16.0891 16.8004 16.3124 16.6623 16.5189 16.5013Z" fill="${color || '#1C274C'}"/>
<path d="M22.3665 10.6922C23.2112 9.84754 23.2112 8.47812 22.3665 7.63348C21.5219 6.78884 20.1525 6.78884 19.3078 7.63348L19.1806 7.76071C19.0578 7.88348 19.0022 8.05496 19.0329 8.22586C19.0522 8.33336 19.0879 8.49053 19.153 8.67807C19.2831 9.05314 19.5288 9.54549 19.9917 10.0083C20.4545 10.4712 20.9469 10.7169 21.3219 10.847C21.5095 10.9121 21.6666 10.9478 21.7741 10.9671C21.945 10.9978 22.1165 10.9422 22.2393 10.8194L22.3665 10.6922Z" fill="${color || '#1C274C'}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.17157 3.17157C3 4.34315 3 6.22876 3 10V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H13C16.7712 22 18.6569 22 19.8284 20.8284C20.9812 19.6756 20.9997 17.8316 21 14.1801L18.1817 16.9984C17.9119 17.2683 17.691 17.4894 17.4415 17.6841C17.1491 17.9121 16.8328 18.1076 16.4981 18.2671C16.2124 18.4032 15.9159 18.502 15.5538 18.6225L13.2421 19.3931C12.4935 19.6426 11.6682 19.4478 11.1102 18.8898C10.5523 18.3318 10.3574 17.5065 10.607 16.7579L10.8805 15.9375L11.3556 14.5121L11.3775 14.4463C11.4981 14.0842 11.5968 13.7876 11.7329 13.5019C11.8924 13.1672 12.0879 12.8509 12.316 12.5586C12.5106 12.309 12.7317 12.0881 13.0017 11.8183L17.0081 7.81188L18.12 6.70004L18.2472 6.57282C18.9626 5.85741 19.9003 5.49981 20.838 5.5C20.6867 4.46945 20.3941 3.73727 19.8284 3.17157C18.6569 2 16.7712 2 13 2H11C7.22876 2 5.34315 2 4.17157 3.17157ZM7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H14.5C14.9142 8.25 15.25 8.58579 15.25 9C15.25 9.41421 14.9142 9.75 14.5 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9ZM7.25 13C7.25 12.5858 7.58579 12.25 8 12.25H10.5C10.9142 12.25 11.25 12.5858 11.25 13C11.25 13.4142 10.9142 13.75 10.5 13.75H8C7.58579 13.75 7.25 13.4142 7.25 13ZM7.25 17C7.25 16.5858 7.58579 16.25 8 16.25H9.5C9.91421 16.25 10.25 16.5858 10.25 17C10.25 17.4142 9.91421 17.75 9.5 17.75H8C7.58579 17.75 7.25 17.4142 7.25 17Z" fill="${color || '#1C274C'}"/>
</svg>
`,
    }}
  />
);
