import Navigation from "../common/Navigation";
import MobileMenuButton from "../common/MobileMenu";
import {Outlet} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

export default function MobileLayout() {
    const {isLogin} = useAuth();
    return (
        <div className="max-w-[425px] min-w-[425px] mx-auto relative flex flex-col justify-between h-full">
            {isLogin ? <Navigation/> : <></>}
            <main className="py-3 px-2 overflow-x-hidden bg-[#081325] flex-1 text-white ---pb-[9.25rem]">
                <Outlet/>
            </main>
            <MobileMenuButton/>
        </div>
    );
}
