import {fetchData} from "./fetch";

const getUser = async () => {
    try {
        const variables = {
            url: "http://93.115.146.206/api/v1/UserManagement/RegisterMobileAndSendSMS",
            variables: {userName: ""}
        };
        const result = await fetchData(variables, "POST");

        if (result) {
            return {
                data: result,
                status: true,
            };
        } else {
            return {
                errors: result?.errors || false,
                status: result?.status || false,
            };
        }
    } catch (e) {
        console.error(e);
        return {
            errors: e?.message,
            status: false,
        };
    }
};

export {getUser};
