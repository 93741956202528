import {convertEnToPe} from "../../utiles/Ops";
import {IconInvoice} from "../Icons/Invoice";

export const InvoiceMenu = ({user}) => {
  return (
    <div className="flex justify-between items-start w-full bg-[#69b7ff] rounded p-3 mb-2">
      <div className="flex gap-x-2">
        <IconInvoice color="white" />
        <div>فاکتور خرید</div>
      </div>
        <div className="flex gap-x-2">
            <span>{convertEnToPe(user?.vwInvoices?.length)}</span>
        </div>
    </div>
  );
};
