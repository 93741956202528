import Helmet from "react-helmet";
import {
  useEffect,
  // useRef,
  useState,
} from "react";
import { getUser } from "../services/get-user";
import { Spinner } from "../compoenents/common/Spinner";
// import { DatePicker } from "zaman";
import { useAuth } from "../hooks/useAuth";
// import { IconEdit } from "../compoenents/Icons/User";
// import { validateInputNumber } from "../utiles/Ops";

// const PROFILE_FORM_REQUIRED_VALUES = [
//   "username",
//   "email",
//   "birth",
//   "phone",
//   "marital_status",
//   "codeMelli",
// ];
export default function ProfilePage() {
  const { user } = useAuth();
  // const [input, setInput] = useState({});
  // const [validationErr, setValidationErr] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [, setData] = useState(null);
  const [, setError] = useState(null);
  // const validatedAll = useRef(true);


  if (isPending) return <Spinner full />;
  // const formOnChangeHandler = ({ target: { value, name } }) => {
  //   setValidationErr({});
  //   validatedAll.current = true;
  //   const _input = { ...input, [name]: value };
  //   setInput(_input);
  // };
  //
  // const validateForm = () => {
  //   if (validatedAll.current) {
  //     let validation = {};
  //     let isValid = true;
  //     PROFILE_FORM_REQUIRED_VALUES.forEach((field) => {
  //       if (!input[field] || input[field] === "") {
  //         validation[field] = "این فیلد اجباری است.";
  //         isValid = false;
  //       }
  //     });
  //     setValidationErr(validation);
  //     return isValid;
  //   }
  // };

  // const submitForm = () => {
  //   if (validateForm()) {
  //     console.log(input);
  //   }
  // };
  return (
    <div>
      <Helmet>
        <title>صفحه مشخصات کاربر</title>
      </Helmet>
      <div className="p-5">
        <h2 className="p-2 flex justify-center text-3xl mb-8">مشخصات کاربر</h2>
        <div className="flex flex-col gap-y-3 mb-4">
          <label htmlFor="username" className="block">
            نام :
          </label>
          <input
            disabled
            defaultValue={user?.party?.name}
            name="username"
            type="text"
            autoComplete="off"
            className="input-form"
            // onChange={formOnChangeHandler}
          />
          {/*{validationErr.username ? (*/}
          {/*  <div className="text-red-500">{validationErr.username}</div>*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
        </div>
        <div className="flex flex-col gap-y-3 mb-4">
          <label htmlFor="username" className="block">
            نام خانوادگی :
          </label>
          <input
            disabled
            defaultValue={user?.party?.lastName}
            name="username"
            type="text"
            autoComplete="off"
            className="input-form"
            // onChange={formOnChangeHandler}
          />
          {/*{validationErr.username ? (*/}
          {/*  <div className="text-red-500">{validationErr.username}</div>*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
        </div>
        <div className="flex flex-col gap-y-3 mb-4">
          <label htmlFor="username" className="block">
            تلفن همراه :
          </label>
          <input
            disabled
            defaultValue={user?.party?.customerPhone}
            name="username"
            type="text"
            autoComplete="off"
            className="input-form"
            // onChange={formOnChangeHandler}
          />
          {/*{validationErr.username ? (*/}
          {/*  <div className="text-red-500">{validationErr.username}</div>*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
        </div>
        {/*<div className="flex flex-col gap-y-3 mb-4">*/}
        {/*  <label htmlFor="username" className="block">*/}
        {/*    کدملی :*/}
        {/*  </label>*/}
        {/*  <input*/}
        {/*      name="codeMelli"*/}
        {/*      type="tel"*/}
        {/*      value={input?.codeMelli}*/}
        {/*      autoComplete="off"*/}
        {/*      className="input-form"*/}
        {/*      min={10}*/}
        {/*      max={10}*/}
        {/*      onChange={(e) => {*/}
        {/*        if (validateInputNumber(e.target.value, 10))*/}
        {/*          formOnChangeHandler(e);*/}
        {/*      }}*/}
        {/*  />*/}
        {/*  {validationErr.codeMelli ? (*/}
        {/*      <div className="text-red-500">{validationErr.codeMelli}</div>*/}
        {/*  ) : (*/}
        {/*      ""*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<div className="flex flex-col gap-y-3 mb-4">*/}
        {/*  <label htmlFor="username" className="block">*/}
        {/*    وضعیت تأهل :*/}
        {/*  </label>*/}
        {/*  <div className="p-5 rounded-xl border border-gray-200 flex justify-between">*/}
        {/*    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">*/}
        {/*      <label*/}
        {/*          className="mt-px inline-block pl-2 hover:cursor-pointer"*/}
        {/*          htmlFor="marital_status_defalt_1"*/}
        {/*      >*/}
        {/*        متاهل*/}
        {/*      </label>*/}
        {/*      <input*/}
        {/*          className="radio"*/}
        {/*          type="radio"*/}
        {/*          name="marital_status"*/}
        {/*          id="marital_status_defalt_1"*/}
        {/*          onChange={formOnChangeHandler}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">*/}
        {/*      <label*/}
        {/*          className="mt-px inline-block pl-2 hover:cursor-pointer"*/}
        {/*          htmlFor="marital_status_defalt_2"*/}
        {/*      >*/}
        {/*        مجرد*/}
        {/*      </label>*/}
        {/*      <input*/}
        {/*          className="radio"*/}
        {/*          type="radio"*/}
        {/*          name="marital_status"*/}
        {/*          id="marital_status_defalt_2"*/}
        {/*          onChange={formOnChangeHandler}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  {validationErr.marital_status ? (*/}
        {/*      <div className="text-red-500">{validationErr.marital_status}</div>*/}
        {/*  ) : (*/}
        {/*      ""*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<div className="flex flex-col gap-y-3 mb-2">*/}
        {/*  <label htmlFor="username" className="block">*/}
        {/*    تاریخ تولد :*/}
        {/*  </label>*/}
        {/*  <DatePicker*/}
        {/*      inputClass="input-form"*/}
        {/*      persianDigits={true}*/}
        {/*      autoUpdate="true"*/}
        {/*      dateFormat="Y/m/d"*/}
        {/*      va*/}
        {/*      theme="dark"*/}
        {/*      onChange={(value) =>*/}
        {/*          formOnChangeHandler({target: {value, name: "birth"}})*/}
        {/*      }*/}
        {/*  />*/}
        {/*  {validationErr?.birth ? (*/}
        {/*      <div className="text-red-500">{validationErr?.birth}</div>*/}
        {/*  ) : (*/}
        {/*      ""*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<button*/}
        {/*    type="button"*/}
        {/*    onClick={submitForm}*/}
        {/*    className="px-4 flex justify-center py-3 gap-x-3 rounded bg-[#31439d] mt-14 w-full"*/}
        {/*>*/}
        {/*  <IconEdit color="white"/>*/}
        {/*  <span>ویرایش</span>*/}
        {/*</button>*/}
      </div>
      {/*<h1>صفحه مشخصات کاربر</h1>*/}
      {/*{user ? (*/}
      {/*  <pre className="ltr">{JSON.stringify(user, undefined, 2)}</pre>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}
      {/*{data ? (*/}
      {/*  <pre className="ltr">{JSON.stringify(data, undefined, 2)}</pre>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}
      {/*{error ? error : ""}*/}
    </div>
  );
}
