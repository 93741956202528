import React from "react";
import {Route, BrowserRouter, Routes} from "react-router-dom";

//pages
import MobileLayout from "./compoenents/Layouts/MobileLayout";
import ProfilePage from "./pages/Profile";
import ShopPage from "./pages/Shop";
import FavePage from "./pages/Fav";
import Page404 from "./pages/404";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
// import { ProtectedRoute } from "./protectedRoute";
import {AuthProvider} from "./hooks/useAuth";
import Page401 from "./pages/401";
import {ProtectedRoute} from "./protectedRoute";
import Invoices from "./pages/Invoices";
import Friends from "./pages/Friends";
import Invoice from "./pages/Invoice";

function AppRoutes() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<MobileLayout/>}>
                        <Route path="*" element={<Page404/>}/>
                        <Route path="401" element={<Page401/>}/>
                        <Route path="login" element={<LoginPage/>}/>
                        <Route index element={<ProtectedRoute><HomePage/></ProtectedRoute>}/>
                        <Route path="/home" element={
                            <ProtectedRoute>
                                <HomePage/>
                            </ProtectedRoute>}/>
                        <Route path="/shop" element={<ProtectedRoute><ShopPage/></ProtectedRoute>}/>
                        <Route path="/fav" element={<ProtectedRoute><FavePage/></ProtectedRoute>}/>
                        <Route path="/invoices" element={<ProtectedRoute><Invoices/></ProtectedRoute>}/>
                        <Route path="/friends" element={<ProtectedRoute><Friends/></ProtectedRoute>}/>
                        {/*<Route path="/user-invoice/:invoice_id" element={<ProtectedRoute><Invoice/></ProtectedRoute>}/>*/}
                        <Route path="/user-invoice/:invoice_id" element={<Invoice/>}/>
                        <Route
                            path="/profile"
                            element={
                                <ProtectedRoute>
                                    <ProfilePage/>
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default AppRoutes;
