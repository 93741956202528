export const Spinner = ({ className, width, height, full, color, border }) => {
  return (
    <div className={`${full ? `m-auto mt-[35%] flex w-full` : ""}`}>
      <span
        className={`${className} m-auto inline-block 
        ${width || "h-[64px]"} 
        ${height || "w-[64px]"}  
        ${color || "border-l-[#ccc]"}  
        animate-spin rounded-full border-[${border || 4}px] border-[#222c3d] align-middle`}
      ></span>
    </div>
  );
};
