import {convertEnToPe} from "../../utiles/Ops";
import {IconUserSocial} from "../Icons/User";
import {useNavigate} from "react-router-dom";

export const FriendsMenu = ({user}) => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-between items-start w-full bg-[#5cdb8d] rounded p-3 mb-2 cursor-pointer" onClick={()=>navigate('/friends')}>
            <div className="flex gap-x-2">
                <IconUserSocial color="white"/>
                <div>دوستان</div>
            </div>
            <div className="flex gap-x-2">
                <span>{convertEnToPe(user?.partyRelateds?.length)}</span>
            </div>
        </div>
    );
};
