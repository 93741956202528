import {IconShop} from "./compoenents/Icons/Shop";
import {convertToPrice} from "./utiles/Ops";
import {LogoBase} from "./compoenents/common/Logo";
import Barcode from "react-barcode";
import {useEffect, useState} from "react";

export const FlipClubCustomer = ({user}) => {
    const [userData, setUserData] = useState({});
    useEffect(() => {
        setUserData(user);
    }, [user]);
    return (
        <div className="flip-card">
            <div className="flip-card-inner">
                <div
                    className="flip-card-front bg-[#e4272b] w-full min-h-[272px] rounded p-5 flex justify-between items-center flex-col">
                    <div className="flex justify-center flex-col items-center gap-y-2">
                        {/*<Logo/>*/}
                        <LogoBase/>
                        {/*<div>لوگو اینجا</div>*/}
                    </div>
                    <div className="flex justify-center flex-col items-center gap-y-2">
                        <span>{userData?.party?.name + " " + userData?.party?.lastName}</span>
                    </div>
                    <div className="flex gap-x-3 justify-between w-full items-center">
                        <div className="flex gap-x-2">
                            <span>{convertToPrice(userData?.sumPriceOfInvoice)}</span>
                            <span>ریال</span>
                        </div>
                        <div>
                            <IconShop color="#fff" width={46}/>
                        </div>
                    </div>
                </div>
                <div
                    className="flip-card-back bg-[#e4272b] w-full min-h-[272px] rounded p-5 flex justify-between items-center flex-col">
                    <div className="flex flex-col justify-center items-center gap-y-3">
                        <div className="rounded overflow-hidden">
                            <img src="/images/qrcode.png" width={64} alt="flip barcode"/>
                        </div>
                        <div>کلاب ولی متفاوت</div>
                    </div>
                    <div className="flex flex-col gap-x-3 gap-y-2 justify-between w-full items-center ">
                        <div className="flex w-full h-[32px] bg-white rounded items-center justify-center">
                                <span
                                    className="text-[#e4272b]">{userData?.party?.name + " " + userData?.party?.lastName}</span>
                        </div>
                        <div className="rounded overflow-hidden">
                            <Barcode renderer="img" height="50" value={userData?.party?.partyId}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
