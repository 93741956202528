import {convertEnToPe} from "../../utiles/Ops";
import {IconSetting} from "../Icons/Setting";

export const CouponMenu = ({user}) => {
  return (
    <div className="flex justify-between items-start w-full bg-[#eb658a] rounded p-3 mb-2">
      <div className="flex gap-x-2">
        <IconSetting color="white" />
        <div>کوپن</div>
      </div>
      <div className="flex gap-x-2">
        <span>{convertEnToPe(0)}</span>
      </div>
    </div>
  );
};
