export const HumberMenuIcon = ({ className = "w-5 h-5" }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg
    class="${className}"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 14"
>
    <path
        stroke="#ccc"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M1 1h15M1 7h15M1 13h15"
    />
</svg>
`,
    }}
  />
);
