import {useState} from "react";

export const useLocalStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(keyName);
            if (value) {
                return keyName === 'token' ? value : JSON.parse(value);
            } else if (defaultValue) {
                window.localStorage.setItem(keyName, defaultValue);
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });
    const setValue = (newValue) => {
        try {
            if (newValue)
                window.localStorage.setItem(keyName, keyName === 'token' ? newValue : JSON.stringify(newValue));
            else
                window.localStorage.removeItem(keyName);
        } catch (err) {
            console.log(err);
        }
        setStoredValue(newValue);
    };
    return [storedValue, setValue];
};
