import Helmet from "react-helmet";

export default function Page404() {
    return <div>
        <Helmet>
            <title>صفحه 404</title>
        </Helmet>
        صفحه مورد نظر پیدا نشد
    </div>;
}
