import {FlipClubCustomer} from "../FlipClubCustomer";
import {CustomerLevel} from "./CustomerLevel";
import {HomeMenu} from "../compoenents/HomeMenu/Home";
import {GiftMenu} from "../compoenents/HomeMenu/GiftMenu";
import {CouponMenu} from "../compoenents/HomeMenu/CouponMenu";
import {InvoiceMenu} from "../compoenents/HomeMenu/InvoiceMenu";
import {FriendsMenu} from "../compoenents/HomeMenu/FriendsMenu";
import {convertEnToPe, convertToPrice, ToShamsiEnToPe} from "../utiles/Ops";
import {useEffect, useState} from "react";
import {getUserData} from "../services/login";
import {useAuth} from "../hooks/useAuth";
import Helmet from "react-helmet";
import {IconArrowLeft} from "../compoenents/Icons/Arrows";
import {useNavigate} from "react-router-dom";
import {Spinner} from "../compoenents/common/Spinner";

export default function HomePage() {
    const {setUser, token, logout} = useAuth();
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUserData().then((_userData) => {
            if (_userData?.status) {
                setUserData(_userData?.data);
                setUser(_userData?.data);
            } else {
                logout();
                navigate("/login");
            }
            setLoading(false);
        });
    }, [token]);

    return (
        <div>
            <Helmet>
                <title>خانه</title>
            </Helmet>
            {loading ? (
                <Spinner full/>
            ) : (<div>
                <div className="grid grid-cols-2 gap-2">
                    <FlipClubCustomer user={userData}/>
                    <CustomerLevel user={userData}/>
                </div>
                <div className="flex flex-col mt-2">
                    <HomeMenu user={userData}/>
                    <GiftMenu user={userData}/>
                    <CouponMenu user={userData}/>
                    <InvoiceMenu user={userData}/>
                    <FriendsMenu user={userData}/>
                </div>
                <div className="rounded bg-[#221f3f] p-2 cursor-pointer">
                    <h1>آخرین فاکتور ها</h1>
                    <div className="mt-5">
                        {userData?.vwInvoices?.slice(0, 3)?.map((item, key) => (
                            <div key={key}
                                 className="cursor-pointer"
                                 onClick={() => navigate(`/user-invoice/${item.invoiceId}`)}>
                                <div className="flex justify-between items-center gap-y-2 px-2 mb-2">
                                    <div>{item.name}</div>
                                    <div className="flex gap-x-2">
                                        <span>{convertToPrice(item?.netPrice)}</span>
                                        <span>ریال</span>
                                    </div>
                                </div>

                                <hr className="w-full mb-2"/>
                                <div className="flex justify-between items-center gap-y-2 px-2">
                <span className="text-blue-500">
                  {convertEnToPe(item?.invoiceId)}
                </span>
                                    <span className="text-green-800">
                  {ToShamsiEnToPe(item?.creationDate)}
                </span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex mt-10" onClick={() => navigate("/invoices")}>
                        <span>نمایش همه</span>
                        <IconArrowLeft/>
                    </div>
                </div>
            </div>)
            }
        </div>
    );
}
